<template>
  <div class="container">
    <transition name="fade">
      <router-view @showParent="showParent"> </router-view>
    </transition>

    <div v-if="showParentPage">
      <v-breadcrumbs>
        <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }">
          {{ $vuetify.lang.t("$vuetify.base.home") }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
        <v-breadcrumbs-item
          :exact="true"
          :to="{ name: 'processWorksheet', params: {} }"
          active
        >
          {{ $vuetify.lang.t("$vuetify.worksheet.worksheet") }}
        </v-breadcrumbs-item>
      </v-breadcrumbs>

      <div>
        <div class="d-flex justify-space-between flex-column flex-md-row mb-4">
          <v-text-field
            outlined
            clearable
            v-model="parameters.wsId"
            :label="$vuetify.lang.t('$vuetify.worksheet.worksheet') + ' ' + $vuetify.lang.t('$vuetify.label.id')"
            @keyup.enter="search"
            @click:append="search"
            @click:clear="clearSearch"
            append-icon="mdi-magnify"
            hide-details
          />
          <v-divider vertical class="mx-2 mt-0 mt-sm-4 my-auto" />
          <page-params
            pageName="processWorksheet"
            vuexName="worksheetSearchParameters"
            :parameters="parameters"
            @onLoad="search()"
            outlined
            hide-details
          />
        </div>
        <v-expansion-panels v-model="showSearch">
          <v-expansion-panel>
            <v-expansion-panel-header class="d-flex justify-space-between">
              <div class="d-flex flex-column flex-md-row">
                <span> {{ $vuetify.lang.t("$vuetify.base.search") }} </span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="d-flex justify-space-between flex-md-row flex-column">
                <v-text-field
                  class="mr-md-2 mb-6 mb-md-0"
                  v-model="parameters.startDate"
                  :label="$vuetify.lang.t('$vuetify.base.startDate')"
                  type="date"
                  clearable
                  dense
                  hide-details
                  :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                  class="ml-0 ml-lg-4"
                  v-model="parameters.endDate"
                  :label="$vuetify.lang.t('$vuetify.base.endDate')"
                  type="date"
                  clearable
                  dense
                  hide-details
                  :rules="[
                    rules.dateRange(parameters.startDate, parameters.endDate),
                  ]"
                ></v-text-field>
              </div>
              <div>
                <v-select
                  class="mt-5"
                  v-model="parameters.staffId"
                  :items="users"
                  item-text="firstName"
                  item-value="id"
                  label="Filter by user"
                  placeholder="All Users"
                  clearable
                />
              </div>
              <div>
                <v-select
                  class="mt-2"
                  v-model="parameters.filterTypeId"
                  :items="worksheetTypeDefinitions"
                  item-text="worksheetType"
                  item-value="id"
                  label="Filter by Worksheet Type"
                  placeholder="All Worksheet Types"
                  clearable
                  @change="worksheetTypeChange"
                />
              </div>
              <div v-if="worksheetType && worksheetType.hasProcessType">
                <v-select
                  v-if="processTypes"
                  clearable
                  dense
                  outlined
                  label="Manufactoring Process Types"
                  v-model="parameters.processTypeId"
                  :items="processTypes"
                  item-text="name"
                  item-value="id"
                />
              </div>

              <div v-if="worksheetType && worksheetType.hasCustomer">
                <v-select
                  v-if="customers"
                  clearable
                  dense
                  outlined
                  label="Customer"
                  v-model="parameters.customerId"
                  :items="customers"
                  item-text="name"
                  item-value="id"
                />
              </div>

              <!-- <div v-if="worksheetType && worksheetType.hasSupplier">
                <v-select
                  v-if="suppliers"
                  clearable
                  dense
                  outlined
                  label="Supplier"
                  v-model="parameters.supplierId"
                  :items="suppliers"
                  item-text="name"
                  item-value="id"
                />
              </div> -->

              <!-- <div v-if="worksheetType && worksheetType.hasSupplier">
                <vehicle-select dense :icon="null" v-model="parameters.vehicle" :allowAddVehicle="false" companyType="SUPPLIER"/>
              </div> -->

              <div class="d-flex flex-column flex-lg-row">
                <v-text-field
                  dense
                  v-model="parameters.searchTerms"
                  @keyup.enter="search"
                  @click:clear="clearSearch"
                  placeholder="Press enter to begin searching memo, notes, document#, product name or plate number"
                  append-icon="mdi-magnify"
                  outlined
                  clearable
                  label="Search"
                  class="flex-grow-1"
                />
                <div
                  class="
                    d-flex
                    flex-row
                    ml-0 ml-lg-4
                    mb-2 mb-lg-0
                    flex-shrink-0 flex-md-row flex-column
                  "
                >
                  <v-select
                    v-if="statuses"
                    clearable
                    dense
                    outlined
                    label="Filter Status"
                    v-model="parameters.status"
                    :items="statuses"
                    item-text="name"
                    item-value="value"
                    class="flex-grow-0 ml-0 ml-lg-5"
                  />
                </div>
              </div>
              <div class="d-flex justify-center">
                <v-btn @click="search" color="primary"> Submit </v-btn>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <v-divider class="my-5" />
      <div class="table-container">
        <div class="text-right">
          <v-btn
            small
            class="my-2 my-md-5 mr-4"
            :loading="isAppLoading"
            @click="downloadExcel()"
            v-if="processWorksheetList && processWorksheetList.length > 0"
          >
            {{ $vuetify.lang.t("$vuetify.base.download") }}
          </v-btn>
        </div>

        <div
          v-if="
            processWorksheetList &&
            processWorksheetList.length > 0 &&
            pagination.totalSize > 0
          "
          class="text-caption font-weight-light ml-4"
        >
          {{ $vuetify.lang.t("$vuetify.label.foundX", pagination.totalSize) }}
        </div>
        <infinite-scroll
          :key="infiniteScrollKey"
          @fetchData="getResources"
          :totalPages.sync="pagination.totalPages"
        >
          <worksheet-card
            v-for="(worksheet, index) in processWorksheetList"
            :worksheet="worksheet"
            :key="worksheet ? worksheet.id : 'null'"
            :showEditButtons="true"
            :index="index + 1"
            @print="print"
            @edit="edit"
            @remove="remove"
          />
        </infinite-scroll>
      </div>
    </div>
    <confirmation-dialog ref="confirm" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { rules } from "@/store/util/rules.js";
import { json2excel } from "js2excel";
const WorksheetCard = () =>
  import("@/components/processWorksheet/WorksheetCard.vue");
const PageParams = () => import("@/components/common/PageParams.vue");
// const VehicleSelect = () =>
//   import("@/components/companyVehicle/VehicleSelect.vue");

export default {
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 5,
        totalSize: null,
        totalPages: null,
      },
      users: [],
      showSearch: false,
      processWorksheetList: [],
      showParentPage: true,
      options: {
        sortBy: ["createTime"],
        sortDesc: ["true"],
      },
      infiniteScrollKey: Date.now(),
      sort: {
        key: null,
      },
      statuses: [
        { name: "Show All", value: null },
        { name: "Pending", value: "PENDING" },
        { name: "Submitted", value: "SUBMITTED" },
        { name: "Used", value: "USED" },
      ],
      rules,
      autoLoadSavedParams: true,
    };
  },
  components: {
    WorksheetCard,
    PageParams,
    // VehicleSelect
  },
  computed: {
    ...mapGetters("navigation", ["isAppLoading"]),
    ...mapGetters("processes", ["processTypes"]),
    ...mapGetters("company", ["customers", "suppliers"]),
    ...mapGetters("processWorksheet", [
      "processWorksheetProcessed",
      "getProcessWorksheets",
    ]),
    ...mapGetters("worksheetTypeDefinition", ["worksheetTypeDefinitions"]),

    worksheetType() {
      return this.worksheetTypeDefinitions.find(
        (x) => x.id == this.parameters.filterTypeId
      );
    },
    parameters: {
      get() {
        return this.$store.state.worksheetSearchParameters.parameters;
      },
      set(value) {
        this.updateParameters(value);
      },
    },
  },
  created() {
    let initWorksheetId = this.$route.query.wsId;
    if (initWorksheetId) {
      this.autoLoadSavedParams = false;
      this.parameters.wsId = initWorksheetId;
    }

    this.fetchWorksheetTypeDefinitions();
    this.loadProcessTypes();
    this.fetchWorksheetUsers().then((users) => {
      this.users = users;
    });

    this.fetchCustomers();
    this.fetchSuppliers();
  },
  methods: {
    ...mapActions("processes", ["loadProcessTypes"]),
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    ...mapActions("processWorksheet", [
      "deleteProcessWorksheet",
      "fetchProcessWorksheets",
      "fetchWorksheetUsers",
    ]),
    ...mapActions("worksheetTypeDefinition", ["fetchWorksheetTypeDefinitions"]),
    ...mapActions("inventoryReservation", ["releaseInventoryReservation"]),
    ...mapActions("worksheetSearchParameters", ["updateParameters"]),
    ...mapActions("company", ["fetchSuppliers", "fetchCustomers"]),
    initDefaultDates() {
      if (!this.parameters.startDate) {
        this.parameters.startDate = moment()
          .subtract(1, "month")
          .format("YYYY-MM-DD");
      }
      if (!this.parameters.endDate) {
        this.parameters.endDate = moment().format("YYYY-MM-DD");
      }
    },
    view(item) {
      this.$router.push({
        name: "processWorksheetView",
        params: { id: item.id },
      });
    },
    edit(item) {
      // this.$router.push({
      //   name: "processWorksheetEdit",
      //   params: { id: item.id },
      // });
      this.$router.push({
        name: "MyWorksheets",
        params: { wsId: item.id },
        query: { override: true },
      });
    },
    print(item) {
      if (item.customerId) {
        this.$router.push({
          name: "worksheetPackingSlip",
          params: { id: item.id },
        });
      } else {
        this.$router.push({
          name: "processWorksheetPrintQR",
          params: { id: item.id },
        });
      }
    },
    async remove(item) {
      let confirm = await this.$refs.confirm.open(
        "Confirm Delete",
        "Do you want to delete this Worksheet?"
      );
      if (confirm) {
        this.deleteProcessWorksheet(item.id)
          .then(() => {
            this.addSuccessMessage("Worksheet was deleted successfully");

            //release all reservations on the inventories used by worksheet
            const worksheetId = item.id;
            this.releaseInventoryReservation({ worksheetId });

            const deleteIndex = this.processWorksheetList.findIndex(
              (ws) => ws.id == worksheetId
            );
            this.processWorksheetList.splice(deleteIndex, 1);
            // this.getResources();
          })
          .catch(() => {
            this.addErrorMessage("Server response with error ");
          });
      }
    },
    prepParams(pageIndex, pageSize) {
      let sortBy =
        this.options["sortBy"] && this.options["sortBy"].length > 0
          ? this.options.sortBy[0]
          : "createTime";
      if (sortBy == "productName") {
        sortBy = "product.name";
      }
      if (sortBy == "processTypeName") {
        sortBy = "processType.name";
      }

      const sortDesc =
        this.options["sortDesc"] && this.options["sortDesc"].length > 0
          ? this.options.sortDesc[0]
          : true;

      this.initDefaultDates();

      const params = {
        page: pageIndex ? pageIndex : 0,
        size: pageSize,
        sort: `${sortBy}${sortDesc ? ",desc" : ""}`,
        worksheetTypeId: this.parameters.filterTypeId,
        staffId: this.parameters.staffId,
        processTypeId: this.parameters.processTypeId,
        customerId: this.parameters.customerId,
        supplierId: this.parameters.supplierId,
        vehicleId: this.parameters.vehicle ? this.parameters.vehicle.id : null,
        start: this.parameters.startDate,
        end: this.parameters.endDate,
        status: this.parameters.status,
        locationId: this.parameters.locationId,
        id: this.parameters.wsId,
      };

      if (this.parameters.searchTerms) {
        params.searchTerms = this.parameters.searchTerms.trim();
      }

      return params;
    },
    getResources({ page, done, empty }) {
      const params = this.prepParams(page, this.pagination.pageSize);

      this.fetchProcessWorksheets(params)
        .then((response) => {
          if (response) {
            const newPage = response.content;
            this.pagination.totalSize = response.totalElements;
            this.pagination.totalPages = response.totalPages;
            this.processWorksheetList =
              this.processWorksheetList.concat(newPage);
            if (this.pagination.totalSize > 0) {
              done();
            } else {
              empty();
            }
          }
        })
        .catch((error) => {
          this.addErrorMessage("Server response with error " + error);
        });
    },
    resetResults() {
      this.processWorksheetList = [];
      this.pagination.totalPages = null;
      this.infiniteScrollKey = Date.now();
    },
    totalWeight(entries) {
      if (entries && entries.length > 0) {
        return entries.reduce((sum, a) => {
          return (sum += a.netWeight);
        }, 0);
      } else {
        return 0;
      }
    },
    downloadExcel() {
      const params = this.prepParams(0, 100000);
      this.fetchProcessWorksheets(params)
        .then((response) => {
          const content = response.content;
          if (content && content.length > 0) {
            content.forEach((c) => {
              c.totalWeight = this.totalWeight(c.entries);
            });

            try {
              json2excel({
                data: content,
                name: "worksheets",
                formateDate: "yyyy-mm-dd",
              });
            } catch (e) {
              this.addErrorMessage(this.errorMsg);
            }
          } else {
            this.addErrorMessage("No data found to download");
          }
        })
        .catch((error) => {
          this.addErrorMessage("Server response with error " + error);
        });
    },
    showParent(show) {
      this.showParentPage = show;
    },
    search() {
      this.resetResults();
      this.showSearch = false;
    },
    clearSearch() {
      this.parameters.searchTerms = null;
      this.resetResults();
    },
    worksheetTypeChange() {
      if (this.worksheetType.hasCustomer) {
        this.fetchCustomers();
      } else if (this.worksheetType.hasSupplier) {
        this.fetchSuppliers();
      }
    },
  },
  watch: {
    "pagination.numberRegisterForPage": function () {
      this.pagination.current = -1;
    },
  },
  beforeRouteUpdate(to, from, next) {
    const toDepth = to.path.split("/").length;
    const fromDepth = from.path.split("/").length;
    this.showParentPage = toDepth < fromDepth;
    if (this.showParentPage) {
      this.resetResults();
    }
    next();
  },
};
</script>
